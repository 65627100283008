dmx.mediumEditor.Extension('auto-list', {

    init: function() {
        MediumEditor.Extension.prototype.init.apply(this, arguments);

        this.subscribe('editableKeypress', this.onKeypress.bind(this));
    },

    onKeypress: function (keyPressEvent) {
        if (MediumEditor.util.isKey(keyPressEvent, [MediumEditor.util.keyCode.SPACE])) {
            var list_start = this.base.getSelectedParentElement().textContent;

            if (list_start == '1.' || list_start == '*') {
                this.base.execAction(list_start == '*' ? 'insertunorderedlist' : 'insertorderedlist');
                this.base.getSelectedParentElement().textContent = '';
                keyPressEvent.preventDefault();
            }
        }
    }

});
