dmx.mediumEditor.Extension('alert', {

    init: function() {
        MediumEditor.Extension.prototype.init.apply(this, arguments);

        this.getEditorOption('elementsContainer').appendChild(this.getAlertElement());
    },

    createAlertElement: function() {
        var elm = this.document.createElement('div');

        elm.id = 'medium-editor-alert-' + this.getEditorId();
        elm.className = 'medium-editor-alert-overlay';
        elm.innerHTML = '<div class="medium-editor-toolbar medium-editor-toolbar-active medium-editor-alert"><ul><li><button class="medium-editor-button-first medium-editor-button-last"></button></li></ul></div>';

        this.on(elm, 'click', this.hide.bind(this));

        return elm;
    },

    getAlertElement: function () {
        if (!this.elm) {
            this.elm = this.createAlertElement();
        }

        return this.elm;
    },

    show: function(message) {
        var elm = this.getAlertElement();
        elm.querySelector('button').innerHTML = message;
        elm.classList.add('medium-editor-alert-active');
    },

    hide: function() {
        this.getAlertElement().classList.remove('medium-editor-alert-active');
    }

});
