dmx.mediumEditor.states = {
    'headers': {
        buttons: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
        checkState: function(node) {
            return ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].indexOf(node.nodeName) != -1;
        }
    }
};

dmx.mediumEditor.Extension('toolbar-states', {

    pendingState: null,

    currentState: null,

    init: function() {
        MediumEditor.Extension.prototype.init.apply(this, arguments);

        this.subscribe('positionToolbar', this.onPositionToolbar.bind(this));
    },

    checkState: function(node) {
        if (!this.pendingState) {
            Object.keys(dmx.mediumEditor.states).forEach(function(name) {
                var state = dmx.mediumEditor.states[name];
                if (state.checkState(node)) {
                    this.pendingState = state;
                }
            }, this);
        }

        if (MediumEditor.util.isMediumEditorElement(node)) {
            this.currentState = this.pendingState;
            this.pendingState = null;
        }
    },

    onPositionToolbar: function() {
        var firstButton, lastButton;
        var toolbar = this.base.getExtensionByName('toolbar');
        var state = this.currentState || { buttons: toolbar.buttons };

        toolbar.forEachExtension(function(extension) {
            if (typeof extension.getButton == 'function') {
                var button = extension.getButton();

                if (state.buttons.indexOf(extension.name) != -1) {
                    button.style.removeProperty('display');
                    firstButton = firstButton || button;
                    lastButton = button;
                } else {
                    button.style.setProperty('display', 'none');
                }

                button.classList.remove(toolbar.firstButtonClass);
                button.classList.remove(toolbar.lastButtonClass);
            }
        });

        if (firstButton && lastButton) {
            firstButton.classList.add(toolbar.firstButtonClass);
            lastButton.classList.add(toolbar.lastButtonClass);
        }
    }

});
