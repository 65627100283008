if (!MediumEditor) {
    alert('Please insert medium-editor.js before dmxMediumEditor.js on your page.');
}

(function() {

dmx.mediumEditor = {};

dmx.mediumEditor.extensions = {};

dmx.mediumEditor.Extension = function(name, cfg) {
    cfg.name = name;
    dmx.mediumEditor.extensions[name] = MediumEditor.Extension.extend(cfg);
};

dmx.mediumEditor.Button = function(name, cfg) {
    cfg.name = name;
    dmx.mediumEditor.extensions[name] = MediumEditor.extensions.button.extend(cfg);
};

dmx.mediumEditor.insertButton = MediumEditor.Extension.extend({

    aria: undefined,

    contentDefault: undefined,

    contentFA: undefined,

    classList: undefined,

    attrs: undefined,

    insertHTML: undefined,

    init: function() {
        MediumEditor.Extension.prototype.init.apply(this, arguments);

        this.button = this.createButton();
        this.on(this.button, 'click', this.handleClick.bind(this));
    },

    getInsertButton: function() {
        return this.button;
    },

    getAria: function() {
        return (typeof this.aria === 'function') ? this.aria(this.base.options) : this.aria;
    },

    createButton: function() {
        var button = this.document.createElement('button'),
            content = this.contentDefault,
            ariaLabel = this.getAria();

        button.classList.add('medium-editor-insertbar-action');
        button.classList.add('medium-editor-insertbar-action-' + this.name);

        if (this.classList) {
            this.classList.forEach(function(className) {
                button.classList.add(className);
            });
        }

        if (ariaLabel) {
            button.setAttribute('title', ariaLabel);
            button.setAttribute('aria-label', ariaLabel);
        }

        if (this.attrs) {
            Object.keys(this.attrs).forEach(function(attr) {
                button.setAttribute(attr, this.attrs[attr]);
            }, this);
        }

        if (this.getEditorOption('buttonLabels') === 'fontawesome' && this.contentFA) {
            content = this.contentFA;
        }

        button.innerHTML = content;

        return button;
    },

    handleClick: function(event) {
        event.preventDefault();
        event.stopPropagation();

        if (this.insertHTML) {
            //var topBlockContainer = MediumEditor.util.getTopBlockContainer(this.base.getSelectedParentElement());
            var topBlockContainer = this.base.getFocusedElement().querySelector('.medium-editor-is-selected');

            if (typeof this.insertHTML === 'function') {
                this.insertHTML(topBlockContainer);
            } else {
                topBlockContainer.insertAdjacentHTML('beforebegin', this.insertHTML);
            }
        }

        this.setSelection();
    },

    setSelection: function() {
        this.base.trigger('editableInput', {}, this.base.getFocusedElement());
        MediumEditor.selection.moveCursor(this.document, this.base.getFocusedElement().querySelector('.medium-editor-is-selected'), 0);
        this.base.getFocusedElement().focus();
        this.base.getExtensionByName('toolbar').checkState();
    }

});

dmx.mediumEditor.InsertButton = function(name, cfg) {
    cfg.name = name;
    dmx.mediumEditor.extensions[name] = dmx.mediumEditor.insertButton.extend(cfg);
};

})();
